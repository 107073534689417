import React, { useEffect } from 'react';
import { observer } from "mobx-react";
import { useStores } from '../helpers';
import { styled } from '../stitches.config';

import { createPaginatorList, PaginatorListType } from "./PaginatorList";
import { IArticle } from "./types/Article";

import PhotoOnTop from "./components/PhotoOnTop";
import PhotoOnLeftBig from "./components/PhotoOnLeftBig";
import PhotoOnLeft from "./components/PhotoOnLeft";

import axios from "axios";
import useMediaQuery from '../helpers/useMediaQuery';

import EmptyArticleObject from "../components/common/EmptyArticle";

interface IPaginatorCategoryPosts {
    label: string;
    type: 'list' | 'grid';
    dataType: 'category' | 'tag';
    id: number;
    pageSize?: number;
    paginationType?: PaginatorListType;
    preloadedPosts?: IArticle[];
};

const PaginatorPosts: React.FC<IPaginatorCategoryPosts> = ({ label, id, type, dataType, pageSize, paginationType = 'PAGINATOR', preloadedPosts = [] }) => {

    const isDesktop = useMediaQuery('bp4');

    const PaginatorList = createPaginatorList<IArticle>(async (page: number) => {
        const { data } = await axios.get(`${window.API_HOST}/api/posts`, {
            params: {
                page: page,
                [dataType + 'Id']: id,
                pageSize,
            }
        });

        return data;
    }, preloadedPosts, EmptyArticleObject as any as IArticle);

    return (
        <Paginator border={type == 'list'}>
            <div className="container">
                <ContentSection className="row" type={type}>
                    <div className={type == 'grid' ? '' : 'col-xs-12'}>
                        <Container>
                            <PaginatorList
                                type={paginationType}
                                label={label}
                                pageSize={pageSize}
                                className={type == 'grid' ? 'row' : ''}
                                onDisplay={(article: IArticle, index: number, articleLength: number, isPlaceholder?: boolean) => (

                                    <PhotoOnLeft boxSize="xs" article={article} key={`${dataType}_${id}_post_${index}`} />

                                )}
                            />
                        </Container>
                    </div>
                </ContentSection>
            </div>
        </Paginator>
    );

};

export default observer(PaginatorPosts);

const Paginator = styled('div', {
    '& > .container': {
        background: '$grayscale0',
    },
    variants: {
        border: {
            true: {
                borderTop: '1px solid #F5F5F5'
            },
            false: {}
        }
    }
});

const Container = styled('div', {

    "& .redesign-paginator-list__items": {

        "@bp4": {
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between"
        }
    },

    "& .photo-on-left": {
        "@bp4": {
            maxWidth: "408px",
        }
    }
});

const ContentSection = styled('div', {
    position: 'relative',
    marginBottom: '40px',
    variants: {
        type: {
            list: {
                maxWidth: "100%",
            },
            grid: {

            }
        }
    }
});